<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="sidebar.collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <template v-for="item in allData.items">
        <template v-if="item && item.children && item.children.length">
          <el-sub-menu :index="item.routePath" :key="item.routePath">
            <template #title>
              <el-icon>
                <component is="Calendar"></component>
              </el-icon>
              <span>{{ item.name }}</span>
            </template>
            <template v-for="(subItem, j) in item.children" :key="j">
              <el-sub-menu
                v-if="subItem && subItem.children && subItem.children.length"
                :index="subItem.routePath"
                :key="subItem.routePath"
              >
                <template #title>{{ subItem.name }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.children"
                  :key="i"
                  :index="threeItem['routePath']"
                >
                  <!-- {{ threeItem['routePath'] }} -->
                  {{ threeItem["name"] }}
                </el-menu-item>
              </el-sub-menu>
              <!-- @click="menuFun(subItem)" -->
              <el-menu-item v-else :index="subItem.routePath">{{ subItem.name }}</el-menu-item>
            </template>
          </el-sub-menu>
        </template>
        <template v-else>
          <!-- @click="menuFun(item)" -->
          <el-menu-item @click="menuFun(item)" :index="item.routePath" :key="item.routePath">
            <el-icon>
              <component is="Odometer"></component>
            </el-icon>
            <template #title>{{ item.name }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { useSidebarStore } from "../store/sidebar";
import { useTagsStore } from "../store/tags";
import { useRoute, useRouter } from "vue-router";
import { loginInteractor } from "@/core";
import { deepCopy } from "kits";

import { menus } from "@/router/menus";

const route = useRoute();
const router = useRouter();
const onRoutes = computed(() => {
  // 如果是新增页面，当跳转到新增页时，父级页面保持高亮-start
  const list = route.path.split("-");
  if (list[1] === "add") {
    return list[0];
  }
  // 如果是新增页面，当跳转到新增页时，父级页面保持高亮-end
  return route.path;
});

const sidebar = useSidebarStore();
const tags = useTagsStore();

//侧边栏数据
let allData: any = reactive({
  items: [],
});

function firstToUpper(str: string) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toLowerCase());
}
const menuFun = (val: any) => {
  let name = val.routePath
    ? val.routePath
        .split("/")
        .map((ele: any) => {
          return firstToUpper(ele);
        })
        .join("") + "Name"
    : "";
  tags.setTagsItem({
    name,
    title: val.name,
    path: val.routePath,
    hideInMenu: val.hideInMenu,
  });
  //.log(tags.nameList,'nameList...')
};

//判断是否有缓存，优先使用缓存
// if(localStorage.router){
//     let routerJson = JSON.parse(localStorage.router);
//     allData.items = routerJson;
// }else{
//     setSlide();
// }

//获取菜单数据，并进行处理
// function setSlide(){
// let loadingMenu = ElLoading.service({
//           lock: true,
//           text: 'Loading...',
//           background: 'rgba(0, 0, 0, 0.7)',
//         })

// loginInteractor.getMenuApi().then((res) => {
// if (res.data && res.data.length > 0) {

localStorage.setItem("routers", JSON.stringify(menus.children)); //[0].children

sidebar.generateRouters(menus.children).then((res: any) => {
  res.forEach((value: any, index: number) => {
    router.addRoute(value);
  });

  // router.addRoute([...arrRouter,{path:'*',name: 'notFound',redirect:'/dashboard'}]) // 动态添加可访问路由表
});

let arr: any = sidebar.routers;

if (
  arr.length &&
  !arr.some((item: any) => {
    return item.routePath === "/dashboard";
  })
) {
  arr.unshift({
    routePath: "/dashboard",
    name: "Home",
  });
}

// 过滤掉 hideInMenu 为true 的节点
const filterHideInMenu = (tree = []) =>
  deepCopy(tree).filter((item: any) => {
    if (item.children?.length) item.children = filterHideInMenu(item.children);
    return !item.hideInMenu;
  });

const newRouters = filterHideInMenu(arr); // 获取到新的路由表

// allData.items = arr;
allData.items = newRouters;
localStorage.setItem("router", JSON.stringify(arr));
// loading.value = false;

// }
// })
// }
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 280px;
}

.sidebar > ul {
  height: 100%;
}
</style>
