import { resultProps } from "element-plus";
import { defineStore } from "pinia";

import Layout from "@/views/home.vue";

function firstToUpper(str: string) {
  // toUpperCase
  return str.toLowerCase().replace(/( |^)[a-z]/g, L => L.toLowerCase());
}

//需要缓存的路由
export const catchRouteArr = [
  "airlogisticsName",
  "sealogisticsName",
  "orderlistName",
  "overbookingName",
  "billlistName"
];
// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap: any, isRewrite = false) {
  return asyncRouterMap
    .filter((ale: any) => ale.routePath != 0)
    .map((route: any) => {
      //   if (isRewrite && route.children) {
      //     route.children = filterChildren(route.children)
      //   }
      if (route.componentPath) {
        // Layout ParentView 组件特殊处理
        if (route.componentPath === "Layout") {
          route.component = Layout;
        } else {
          route.component = loadView(route.componentPath);
        }
        // else if (route.componentPath === 'ParentView') {
        //   route.component = ParentView
        // } else {

        // }
      }
      if (route.children != null && route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, route); //isRewrite
      }
      //   let flag=route.children.filter((ele:any)=> ele.type==1&&ele.routePath!=0).length>0;
      let name = route.routePath
        ? route.routePath
            .split("/")
            .map((ele: any) => {
              return firstToUpper(ele);
            })
            .join("") + "Name"
        : "";
      //.log(name,'name')
      let result = {
        title: route.name,
        name: name,
        path: route.routePath,
        hideInMenu: route.hideInMenu,
        type: route.type,
        component: route.component,
        children: route.children,
        // redirect: route.children&&route.children.length&&flag?route.children[0].path:'',
        meta: {
          title: route.name,
          showParent: route.showParent == 0 ? false : true,
          type: route.type,
          keepAlive: catchRouteArr.includes(name) ? true : false
        }
      };
      return result;
    });
}

export const useSidebarStore = defineStore("sidebar", {
  state: () => {
    return {
      collapse: false,
      routers: [],
      slideRouters: []
    };
  },
  getters: {},
  actions: {
    handleCollapse() {
      this.collapse = !this.collapse;
    },
    generateRouters(data: any) {
      return new Promise((resolve, reject) => {
        this.routers = data;
        this.slideRouters = data;
        // commit("SET_PERMISSIONS",filterPermissions(data))
        let accessRouters = filterAsyncRouter(
          JSON.parse(JSON.stringify(data)),
          true
        );
        // console.log(accessRouters,'accessRouters')
        resolve(accessRouters);
      });
    }
  }
});

export const catchPath = [
  "/customer-Center",
  "/carousel",
  "/best-sellers",
  "/new-arrival",
  "/top-categories",
  "/quote",
  "/returns-refunds",
  "/become-our-supplier",
  "/promotions",
  "/promotions-add",
  "/clearance",
  "/clearance-add",
  "/customer-reviews",
  "/brand-management",
  "/themeActivities",
  "/themeActivities-add"
];

export const loadView = (view: any) => {
  // 路由懒加载
  // console.log(view,'view')
  // 首先把你需要动态路由的组件地址全部获取

  let modules = import.meta.glob("../views/**/*.vue");
  let component = modules[`../views/${view}.vue`];
  return component;
};
